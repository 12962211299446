.add-players,
.actual-tricks,
.choose-tricks,
.scoreboard {
  padding: 10px;

  form {
    display: flex;
    flex-direction: column;
  }

  div {
    margin: 5px 0;
  }

  button {
    margin: 15px 0;
  }
}
